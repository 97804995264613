import {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Breakdown} from "../../../../app/modules/apps/user-management/users-list/core/_models";

type Props = {
  className: string;
  chartHeight: number;
  chartColor?: string;
  stats: Breakdown;
  title?: string;
  loading?: boolean;
  useCustomColors?: boolean;
}

export const PieChart: FC<Props> = ({useCustomColors, className, loading, chartHeight, chartColor, stats, title}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (loading || !chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, stats, chartColor, useCustomColors));
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, chartHeight, chartColor, stats, loading, useCustomColors]);

  return (
    <div className={`card card-flush ${className}`}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>{title}</span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-0 px-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className='min-h-auto ps-4 pe-6 mb-3'
          style={{height: `${chartHeight}px`}}
        ></div>
        {
          loading && (
            <div className="text-center">
              <div className="spinner-border" role="status" style={{width: '6rem', height: '6rem', marginBottom: '100px'}}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )
        }
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartHeight: number, stats: Breakdown, chartColor?: string, useCustomColors?: boolean): ApexOptions => {
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')
  const colors = stats.map((stat) => stat.color || '#4FC9DA');

  const series = stats.map(stat => stat.count);

  return {
    series,
    labels: stats.map(stat => stat.name),
    chart: {
      fontFamily: 'inherit',
      type: 'donut',
      height: chartHeight,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      pie: {
        startAngle: 0,
      }
    },
    legend: {
      show: true,
      position: 'bottom',
      formatter(legendName: string, opts?: any): string {
        return stats[opts.seriesIndex].value || stats[opts.seriesIndex].name;
      }
    },
    dataLabels: {
      enabled: false,
      formatter(val: string | number | number[], opts?: any): string | number {
        return stats[opts.seriesIndex].value || stats[opts.seriesIndex].name;
      }
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
    },
    colors: useCustomColors ? colors : undefined,
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

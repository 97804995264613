/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {LoginForm} from "./LoginForm";
import {LoginNewPassword} from "./LoginNewPassword";

export function Login() {
  const [newPasswordUser, setNewPasswordUser] = useState<any>(null);

  return newPasswordUser ?
    <LoginNewPassword user={newPasswordUser} /> :
    <LoginForm setNewPasswordUser={setNewPasswordUser} />;
}

/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {PageTitle} from './page-title/PageTitle'
import {useLayout} from '../../core'
import {Search} from "../../../partials";

const HeaderTopbar: React.FC = () => {
  const {classes} = useLayout()
  return (
    <div
      className={`${classes.headerContainer} py-6 py-lg-0 d-flex flex-column flex-sm-row align-items-lg-stretch justify-content-sm-between`}
    >
      <PageTitle />

      <div className='d-flex align-items-center overflow-auto pt-3 pt-sm-0'>
        <div className='header-search me-4'>
          <Search />
        </div>

        <div className='d-flex'>

        </div>
      </div>
    </div>
  )
}

export default HeaderTopbar

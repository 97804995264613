import React, {useState} from 'react'
import {ForgotPasswordEmail} from "./ForgotPasswordEmail";
import {ResetPassword} from "./ResetPassword";

export function ForgotPassword() {
  const [awaitingCode, setAwaitingCode] = useState(false);
  const [email, setEmail] = useState<string | undefined>(undefined);

  return awaitingCode ? <ResetPassword email={email || ''} /> : <ForgotPasswordEmail setAwaitingCode={setAwaitingCode} setEmail={setEmail}/>;
}

import React, {useState} from "react";
import {DateRange} from "react-date-range";
import {addDays} from "date-fns";

export type DateFilters = {
  startDate?: string;
  endDate?: string;
}

type DateFiltersContextValue = {
  dateFilters: DateFilters;
  setDateFilters: (dateFilters: DateFilters) => void;
  resetFilters: () => void;
};

const defaultValue: DateFiltersContextValue = {
  dateFilters: {},
  setDateFilters: () => {},
  resetFilters: () => {},
};

export const DateFiltersContext = React.createContext<DateFiltersContextValue>(defaultValue);

export const DateFiltersProvider = (props: React.PropsWithChildren<any>) => {
  const [dateFilters, setDateFilters] = useState<DateFilters>({});

  return (
    <DateFiltersContext.Provider value={{
      dateFilters,
      setDateFilters,
      resetFilters: () => setDateFilters({}),
    }}>
      {props.children}
    </DateFiltersContext.Provider>
  );
};

export const DateFiltersComponent = () => {
  const {dateFilters, setDateFilters, resetFilters} = React.useContext(DateFiltersContext);
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false);

  const dateText = (dateString: string) => {
    const date = new Date(Date.parse(dateString));
    date.setDate(date.getDate() - 1);
    return date
      .toLocaleDateString(
        undefined,
        { year: 'numeric', month: 'long', day: 'numeric' }
      );
  }

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const dateFilterDescription = () => {
    if (dateFilters.startDate && !dateFilters.endDate) {
      return `On or after ${dateText(dateFilters.startDate)}`;
    } else if (!dateFilters.startDate && dateFilters.endDate) {
      return `On or before ${dateText(dateFilters.endDate)}`;
    } else if (dateFilters.startDate && dateFilters.endDate) {
      return `Between ${dateText(dateFilters.startDate)} and ${dateText(dateFilters.endDate)}`;
    } else {
      return 'All time';
    }
  }

  const initialStartDate = dateFilters.startDate ? new Date(dateFilters.startDate) : undefined;
  const initialEndDate = dateFilters.endDate ? new Date(dateFilters.endDate) : new Date();

  const [state, setState] = useState([
    {
      startDate: initialStartDate,
      endDate:  initialEndDate,
      key: 'selection'
    }
  ]);

  return (
    <div style={{width: '500px'}}>
      <div className="input-group mb-3">
        <span className="input-group-text" style={{cursor: 'pointer'}} id="basic-addon1" onClick={() => setShowDateRangePicker(!showDateRangePicker)}>
          <i className="bi bi-filter" />
        </span>
        <input
          type="text"
          className="form-control form-control-sm"
          placeholder="All time"
          aria-label="Date filter"
          aria-describedby="basic-addon1"
          value={dateFilterDescription()}
          onClick={() => setShowDateRangePicker(!showDateRangePicker)}
        />
        <button
          className="btn btn-outline-primary btn-sm"
          type="button"
          style={{border: '1px solid rgb(239, 233, 225)'}}
          onClick={resetFilters}
        >Clear</button>
      </div>
      <div style={{position: 'relative'}}>
        {showDateRangePicker && <div style={{position: 'fixed', left: 0, right: 0, top: 0, bottom: 0}} onClick={() => setShowDateRangePicker(false)} />}
        <div
          style={{
            position: 'absolute',
            maxHeight: showDateRangePicker ? '500px' : '0',
            opacity: showDateRangePicker ? '1' : '0',
            transition: '0.3s ease',
            padding: '20px',
            background: '#fff',
            borderRadius: '6px',
            border: '1px solid rgb(239, 233, 225)',
            left: '52px',
            top: '-8px',
            zIndex: 2,
          }}
        >
          {/*// @ts-ignore*/}
          {showDateRangePicker && <DateRange
            onChange={item => {
              // @ts-ignore
              setState([item.selection]);
              setDateFilters({
                startDate: item.selection.startDate ? formatDate(addDays(item.selection.startDate, 1)) : undefined,
                endDate: item.selection.endDate ? formatDate(addDays(item.selection.endDate, 1)) : undefined,
              });
            }}
            // @ts-ignore
            showSelectionPreview={true}
            startDatePlaceholder=""
            moveRangeOnFirstSelection={false}
            months={1}
            ranges={state}
            direction="horizontal"
            preventSnapRefocus={true}
            calendarFocus="forwards"
            maxDate={new Date()}
          />}
        </div>
      </div>
    </div>
  )
}

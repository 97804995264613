/* eslint-disable jsx-a11y/anchor-is-valid */
import {toAbsoluteUrl} from '../../../helpers'
import React from "react";
import {CurrentUserContext} from "../../../../contexts/CurrentUser";
import {Auth} from "aws-amplify";

const HeaderUserMenu = () => {
  const {currentUser, setCurrentUser} = React.useContext(CurrentUserContext);

  const logout = () => {
    Auth.signOut({global: true}).then(() => {
      setCurrentUser(null);
      // @ts-ignore
      window.location.href = '/home';
    }).catch(() => {
      setCurrentUser(null);
      // @ts-ignore
      window.location.href = '/home';
    });
  }

  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
      data-kt-menu='true'
    >
      {/* begin::Menu item */}
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          {/* begin::Avatar */}
          <div className='symbol symbol-50px me-5'>
            <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
          </div>
          {/* end::Avatar */}

          {/* begin::Username */}
          <div className='d-flex flex-column'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
              {currentUser?.name}
            </div>
            <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
              {currentUser?.email}
            </a>
            {
              currentUser?.insurance_provider_name &&
              <span className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser.insurance_provider_name}
              </span>
            }
          </div>
          {/* end::Username */}
        </div>
      </div>
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      {/*<div className='separator my-2'></div>*/}
      {/* end::Menu separator */}

      {/* begin::Menu item */}
      {/*<div className='menu-item px-5'>*/}
      {/*  <Link to={'/crafted/pages/profile'} className='menu-link px-5'>*/}
      {/*    My Profile*/}
      {/*  </Link>*/}
      {/*</div>*/}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      {/*<div className='menu-item px-5'>*/}
      {/*  <a href='#' className='menu-link px-5'>*/}
      {/*    <span className='menu-text'>My Projects</span>*/}
      {/*    <span className='menu-badge'>*/}
      {/*      <span className='badge badge-light-danger badge-circle fw-bolder fs-7'>3</span>*/}
      {/*    </span>*/}
      {/*  </a>*/}
      {/*</div>*/}
      {/* end::Menu item */}
      {/* begin::Menu item */}
      {/*<div*/}
      {/*  className='menu-item px-5'*/}
      {/*  data-kt-menu-trigger='hover'*/}
      {/*  data-kt-menu-placement='right-end'*/}
      {/*>*/}
      {/*  <a href='#' className='menu-link px-5'>*/}
      {/*    <span className='menu-title'>My Subscription</span>*/}
      {/*    <span className='menu-arrow'></span>*/}
      {/*  </a>*/}

      {/*  /!* begin::Menu sub *!/*/}
      {/*  <div className='menu-sub menu-sub-dropdown w-175px py-4'>*/}
      {/*    /!* begin::Menu item *!/*/}
      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link px-5'>*/}
      {/*        Referrals*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    /!* end::Menu item *!/*/}

      {/*    /!* begin::Menu item *!/*/}
      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link px-5'>*/}
      {/*        Billing*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    /!* end::Menu item *!/*/}

      {/*    /!* begin::Menu item *!/*/}
      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link px-5'>*/}
      {/*        Payments*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    /!* end::Menu item *!/*/}

      {/*    /!* begin::Menu item *!/*/}
      {/*    <div className='menu-item px-3'>*/}
      {/*      <a href='#' className='menu-link d-flex flex-stack px-5'>*/}
      {/*        Statements*/}
      {/*        <i*/}
      {/*          className='fas fa-exclamation-circle ms-2 fs-7'*/}
      {/*          data-bs-toggle='tooltip'*/}
      {/*          title='View your statements'*/}
      {/*        ></i>*/}
      {/*      </a>*/}
      {/*    </div>*/}
      {/*    /!* end::Menu item *!/*/}

      {/*    /!* begin::Menu separator *!/*/}
      {/*    <div className='separator my-2'></div>*/}
      {/*    /!* end::Menu separator *!/*/}

      {/*    /!* begin::Menu item *!/*/}
      {/*    <div className='menu-item px-3'>*/}
      {/*      <div className='menu-content px-3'>*/}
      {/*        <label className='form-check form-switch form-check-custom form-check-solid'>*/}
      {/*          <input*/}
      {/*            className='form-check-input w-30px h-20px'*/}
      {/*            type='checkbox'*/}
      {/*            value='1'*/}
      {/*            checked={true}*/}
      {/*            name='notifications'*/}
      {/*            onChange={() => {}}*/}
      {/*          />*/}
      {/*          <span className='form-check-label text-muted fs-7'>Notifications</span>*/}
      {/*        </label>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*    /!* end::Menu item *!/*/}
      {/*  </div>*/}
      {/*  /!* end::Menu sub *!/*/}
      {/*</div>*/}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      {/*<div className='menu-item px-5'>*/}
      {/*  <a href='#' className='menu-link px-5'>*/}
      {/*    My Statements*/}
      {/*  </a>*/}
      {/*</div>*/}
      {/* end::Menu item */}

      {/* begin::Menu separator */}
      <div className='separator my-2'></div>
      {/* end::Menu separator */}

      {/*<Languages languageMenuPlacement='right-end' />*/}

      {/* begin::Menu item */}
      {/*<div className='menu-item px-5 my-1'>*/}
        {/*// @ts-ignore */}
        {/*<Link to='/crafted/account/settings' className='menu-link px-5'>*/}
        {/*  Account Settings*/}
        {/*</Link>*/}
      {/*</div>*/}
      {/* end::Menu item */}

      {/* begin::Menu item */}
      <div className='menu-item px-5'>
        <a onClick={logout} className='menu-link px-5'>
          Sign Out
        </a>
      </div>
    </div>
  )
}

export {HeaderUserMenu}

import {Auth} from "aws-amplify";

export default function setupAxios(axios: any, store: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: any) => {
      const session = await Auth.currentSession();
      const accessToken = session.getIdToken().getJwtToken();
      const user = await Auth.currentAuthenticatedUser();
      const cognitoId = user.attributes.sub;

      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      if (cognitoId) {
        config.headers.AWS_COGNITO_ID = cognitoId
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

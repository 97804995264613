import React, {FC} from 'react'
import {Link} from "react-router-dom";

type Props = {
  className: string;
  cardNumber: string;
  color: string;
  statistics: string;
  text: string;
  loading?: boolean;
  filterId?: number;
}

export const Metric: FC<Props> = ({filterId, text = 'Average SDOH', loading, className, cardNumber, color, statistics}) => {
  return (
    <div className={`card card-flush ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex justify-content-between flex-column'>
        {/* begin::Section */}
        <div className='d-flex flex-column my-7'>
          {/* begin::Number */}
          {
            loading ? (
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <span className='fw-bold fs-3x text-gray-800 lh-1' style={{fontFamily: "'DM Serif Display', 'sans-serif'"}}
              >{cardNumber}</span>
            )
          }
          {/* end::Number */}
          {/* begin::Follower */}
          <span className='fw-bold fs-6'>{text}</span>
          {/* end::Follower */}
        </div>
        {/* end::Section */}
        {/* begin::Statistics */}
        {
          filterId && (
            <div className='m-0' style={{minHeight: '32px'}}>
              <Link
                to={`/apps/user-management/users?widgetFilter=${filterId}`}
                className='btn btn-primary btn-active btn-sm filter-users-button'
                style={{backgroundColor: '#4280ea !important'}}
              >
                <i className="bi bi-filter" />
                See users
              </Link>
            </div>
          )
        }

      </div>
      {/* end::Body */}
    </div>
  )
}

import React from 'react'
import HeaderTopbar from './HeaderTopbar'
import {Link} from 'react-router-dom'

const HeaderBase: React.FC = () => {
  return (
    <div id='kt_header' className='header align-items-stretch' style={{position: 'fixed', width: 'calc(100vw - 300px)', zIndex: 10000}}>
      <div className='header-brand'>
        <Link to='/'>
            <img src="/media/logos/canopie.png" width={181} alt="Canopie" />
        </Link>
      </div>

      <div className='topbar'>
        <HeaderTopbar />
      </div>
    </div>
  )
}

export default HeaderBase

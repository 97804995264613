import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  return (
    <>
        {/*<AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />*/}
        <AsideMenuItem
            to='/dashboards/home'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title='Dashboard'
            fontIcon='bi-layers'
        />
        <AsideMenuItem
          to='/patients'
          icon='/media/icons/duotune/arrows/arr001.svg'
          title='Individual View'
          fontIcon='bi-layers'
        />
        <AsideMenuItem
            to='/apps/user-management/'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title='Raw Data'
            fontIcon='bi-layers'
        />
    </>
  )
}

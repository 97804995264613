import ReactDOM from 'react-dom'
// Redux
// https://github.com/rt2zz/redux-persist
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store from './setup/redux/Store'
// Axios
import axios from 'axios'
import {Chart, registerables} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// import {ReactQueryDevtools} from 'react-query/devtools'
// Apps
import {GoodI18nProvider} from './_theme/i18n/Goodi18n'
/**
 * TIP: Replace this style import with dark styles to enable dark mode
 *
 * import './_theme/assets/sass/style.dark.scss'
 *
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_theme/assets/css/style.rtl.css'
 **/
import './_theme/assets/sass/style.scss'
import './_theme/assets/sass/plugins.scss'
import './_theme/assets/sass/style.react.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {CurrentUserProvider} from './contexts/CurrentUser';
import {Amplify} from 'aws-amplify';
import {DateFiltersProvider} from "./contexts/DateFilters";

// Configure Amplify
Amplify.configure({
  Auth: {
    /* eslint-disable no-undef */
    region: process.env.REACT_APP_AWS_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID
    /* eslint-enable no-undef */
  },
});

/**
 * Inject Good interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
_redux.setupAxios(axios, store)

Chart.register(...registerables)

const queryClient = new QueryClient()

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <GoodI18nProvider>
      {/*// @ts-ignore */}
      <Provider store={store}>
        {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
        {/*// @ts-ignore */}
        {/*<PersistGate persistor={persistor} loading={<div>Loading...</div>}>*/}
          <CurrentUserProvider>
            <DateFiltersProvider>
              <AppRoutes />
            </DateFiltersProvider>
          </CurrentUserProvider>
        {/*</PersistGate>*/}
      </Provider>
    </GoodI18nProvider>
    {/*<ReactQueryDevtools initialIsOpen={false} />*/}
  </QueryClientProvider>,
  // @ts-ignore
  document.getElementById('root')
);

/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {Auth} from "aws-amplify";

const newPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .matches(/[a-z]/i, 'Must contain at least one letter')
    .matches(/[0-9]/i, 'Must contain at least one number')
    .required('Password is required'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirmation is required'),
})

const initialValues = {
  password: '',
  passwordConfirmation: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function LoginNewPassword({user}: { user: any }) {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema: newPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true);

      Auth.completeNewPassword(user, values.password, {})
        .then(() => {
          Auth.signIn({username: user.username, password: values.password});
        })
        // @ts-ignore
        .catch((e) => {
          setLoading(false);
          setStatus(e.message);
          setSubmitting(false);
        });
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Please set a new password</h1>
      </div>
      {/* begin::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>New Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Confirm Password</label>
            {/* end::Label */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('passwordConfirmation')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
            },
            {
              'is-valid': formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
            }
          )}
        />
        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.passwordConfirmation}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/*/!* begin::Separator *!/*/}
        {/*<div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div>*/}
        {/*/!* end::Separator *!/*/}

        {/*/!* begin::Google link *!/*/}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
        {/*  <img*/}
        {/*    alt='Logo'*/}
        {/*    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}*/}
        {/*    className='h-20px me-3'*/}
        {/*  />*/}
        {/*  Continue with Google*/}
        {/*</a>*/}
        {/*/!* end::Google link *!/*/}

        {/*/!* begin::Google link *!/*/}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>*/}
        {/*  <img*/}
        {/*    alt='Logo'*/}
        {/*    src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}*/}
        {/*    className='h-20px me-3'*/}
        {/*  />*/}
        {/*  Continue with Facebook*/}
        {/*</a>*/}
        {/*/!* end::Google link *!/*/}

        {/*/!* begin::Google link *!/*/}
        {/*<a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>*/}
        {/*  <img*/}
        {/*    alt='Logo'*/}
        {/*    src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}*/}
        {/*    className='h-20px me-3'*/}
        {/*  />*/}
        {/*  Continue with Apple*/}
        {/*</a>*/}
        {/*/!* end::Google link *!/*/}
      </div>
      {/* end::Action */}
    </form>
  )
}

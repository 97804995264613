import {FC, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {Breakdown} from "../../../../app/modules/apps/user-management/users-list/core/_models";
import {Tooltip} from "bootstrap";

type Props = {
  className: string;
  chartHeight: number;
  chartColor?: string;
  title?: string;
  stats: Breakdown;
  loading?: boolean;
  useCustomColors?: boolean;
  showLegend: boolean;
}

export const VerticalBarChart: FC<Props> = ({showLegend, className, chartHeight, chartColor, stats, title, loading}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (loading || !chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartHeight, stats, showLegend))
    if (chart) {
      chart.render();
      Array.from(document.querySelectorAll('.tooltip-label:not([data-bs-toggle])')).forEach((node) => {
        const text = node.querySelector('title')?.innerHTML || '';
        if (!text || !text.length) {
          return;
        }
        node.setAttribute('data-bs-title', text);
        node.setAttribute('data-bs-toggle', 'tooltip');
        new Tooltip(node);
      });
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    }
  }, [chartRef, chartHeight, chartColor, stats, loading])

  return (
    <div className={`card card-flush ${className}`}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        {/* begin::Title */}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bolder text-dark'>{title}</span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-0 px-0'>
        {/* begin::Chart */}
        <div
          ref={chartRef}
          className='min-h-auto ps-4 pe-6 mb-3'
          style={{height: `${chartHeight}px`}}
        ></div>
        {/* end::Chart */}
        {
          loading && (
            <div className="text-center">
              <div className="spinner-border" role="status" style={{width: '6rem', height: '6rem', marginBottom: '100px'}}>
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          )
        }
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartHeight: number, stats: Breakdown, showLegend: boolean): ApexOptions => {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-border-dashed-color')

  const colors = stats.map((stat) => stat.color || '#4FC9DA');

  return {
    series: [
      {
        name: 'Count',
        data: stats.map((stat) => stat.count),
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      // offsetY: 30,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        columnWidth: '35%',
        barHeight: '100%',
        borderRadius: 4,
      },
    },
    legend: {
      show: showLegend,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: stats.map((stat) => stat.name),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: !showLegend,
        trim: true,
        maxHeight: 130,
        style: {
          colors: getCSSVariableValue('--bs-gray-800'),
          fontSize: '14px',
          fontWeight: '600',
          cssClass: 'tooltip-label',
        },
      },
      crosshairs: {
        show: false,
      },
    },
    yaxis: {
      tickAmount: 1,
      min: 0,
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val: number, opts?: any) => {
          return val.toFixed(0);
        }
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      // theme: 'dark',
      x: {
        show: true
      },
      y: {
        formatter: function (value: number) {
          return value.toFixed(0);
        },
        title: {
          formatter: function () {
            return ''
          }
        }
      }
    },
    colors,
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}

import React, {useState} from "react";

export type CurrentUser = {
  id: number;
  cognito_id: string;
  email: string;
  name?: string;
  username: string;
  insurance_provider_id: number;
  insurance_provider_name?: string;
}

type CurrentUserContextValue = {
  currentUser: CurrentUser | null;
  setCurrentUser: (options: CurrentUser | null) => void;
};

const defaultValue: CurrentUserContextValue = {
  currentUser: null,
  setCurrentUser: () => {}
};

export const CurrentUserContext = React.createContext<CurrentUserContextValue>(defaultValue);

export const CurrentUserProvider = (props: React.PropsWithChildren<any>) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);

  return (
    <CurrentUserContext.Provider value={{currentUser, setCurrentUser}}>
      {props.children}
    </CurrentUserContext.Provider>
  );
};

/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useContext, useEffect, useState} from 'react';
import {Auth, Hub} from 'aws-amplify';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {AuthPage, Logout} from '../modules/auth'
import {App} from '../App'
import {CurrentUserContext} from "../../contexts/CurrentUser";
import {getInsuranceProvider, getSelf} from "../modules/apps/user-management/users-list/core/_requests";
// import { Authenticator } from '@aws-amplify/ui-react';
// import { Auth, Hub } from 'aws-amplify';

// aws-amplify defined type is incorrect, so making our own
type CognitoUser = {
  attributes: {
    sub: string;
    email: string;
  },
  username: string;
};

const AppRoutes: FC = () => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const {currentUser, setCurrentUser} = useContext(CurrentUserContext);
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      // @ts-ignore
      .then(user => {
        setUser(user);
      })
      .catch(() => {
        console.log('No signed in user.');
        setHasLoaded(true);
      });

    // @ts-ignore
    Hub.listen('auth', (data) => {
      switch (data.payload.event) {
        case 'signIn':
          if (data.payload.data.attributes) {
            return setUser(data.payload.data);
          }
          break;
        case 'signOut':
        case 'tokenRefresh_failure':
          if (data.payload.event === 'tokenRefresh_failure') {
            alert("Session timed out. Please log in to continue.");
          }
          return setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    if (!user) {
      setCurrentUser(null);

      return;
    }

    getSelf().then((receivedUser) => {
      setCurrentUser(receivedUser);
      setHasLoaded(true);
      getInsuranceProvider(receivedUser.insurance_provider_id).then((insuranceProvider) => {
        setCurrentUser({...receivedUser, insurance_provider_name: insuranceProvider.plan_name});
      });
    });

  }, [user, setCurrentUser]);

  if (!hasLoaded) {
    return null;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              <Route path='/*' element={<PrivateRoutes />} />
              <Route index element={<Navigate to='/dashboards' />} />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}

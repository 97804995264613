import React, {FC, useEffect, useState} from 'react'
import {DashboardViewSection} from "../../modules/apps/user-management/users-list/core/_models";
import {getWidgets} from "../../modules/apps/user-management/users-list/core/_requests";
import Widget from "./Widget";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateFiltersComponent} from "../../../contexts/DateFilters";

export const Home: FC = () => {
  const [widgetSections, setWidgetSections] = useState<DashboardViewSection[]>([]);

  useEffect(() => {
    getWidgets().then(setWidgetSections);
  }, []);

  return (
    <>
      <DateFiltersComponent />
      {/* begin::Row */}
        {
          widgetSections.map((widgetSection) => (
            <div key={widgetSection.id} className='row g-5 g-xxl-10'>
              <div className='d-flex align-items-center mb-0 pt-2'>
                <span className='text-gray-800 fs-2 fw-bolder me-1'>
                  {widgetSection.name ? widgetSection.name : ''}
                </span>
              </div>
              {
                widgetSection.widgets.map((widget) => (
                  <Widget widget={widget} key={widget.id} />
                ))
              }
            </div>
          ))
        }
      {/* end::Row */}
    </>
  )
}

import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_theme/helpers'
import {
  DashboardViewSection,
  Patient,
  RawData,
  ResponseWithCount,
  User,
  UsersQueryResponse,
  WidgetData
} from './_models'
import {CurrentUser} from "../../../../../../contexts/CurrentUser";

export const API_URL = process.env.REACT_APP_API_URL;
const USER_URL = `${API_URL}/reporting/data`;
const RAW_DATA_URL = `${API_URL}/reporting/raw-data`;
const WIDGETS_URL = `${API_URL}/reporting/widgets`;
const GET_USERS_URL = `${API_URL}/users/query`;

const getUsers = (query: string): Promise<UsersQueryResponse> => {
  return axios
    .get(`${GET_USERS_URL}?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
}

const getUserById = (id: ID): Promise<Patient | undefined> => {
  return axios
    .get(`${USER_URL}/${id}`)
    .then((response: AxiosResponse<Patient>) => response.data);
}

type DateOptions = {
  startDate?: string,
  endDate?: string,
}

const getRawData = (page = 0, limit = 50, options: DateOptions = {}, widgetFilter?: string): Promise<ResponseWithCount<RawData>> => {
  const offset = page * limit;

  const params: string[] = [
    `limit=${limit}`,
    `offset=${offset}`,
  ];

  if (options.startDate) {
    params.push(`startDate=${options.startDate}`);
  }

  if (options.endDate) {
    params.push(`endDate=${options.endDate}`);
  }

  if (widgetFilter) {
    params.push(`widgetFilterId=${widgetFilter}`);
  }

  const queryString = `?${params.join('&')}`;

  return axios
    .get(`${RAW_DATA_URL}${queryString}`)
    .then((response: AxiosResponse<RawData>) => {

      return {
        data: response.data,
        count: parseInt(response.headers['count']),
      };
    });
}

const getWidgets = (): Promise<DashboardViewSection[]> => {
  return axios
    .get(WIDGETS_URL)
    .then((response: AxiosResponse<DashboardViewSection[]>) => response.data);
}

const getWidgetConfiguration = (id: number, options: DateOptions = {}): Promise<WidgetData> => {
  const params: string[] = [];

  if (options.startDate) {
    params.push(`startDate=${options.startDate}`);
  }

  if (options.endDate) {
    params.push(`endDate=${options.endDate}`);
  }

  const queryString = params.length ? `?${params.join('&')}` : '';

  return axios
    .get(`${WIDGETS_URL}/${id}${queryString}`)
    .then((response: AxiosResponse<WidgetData>) => response.data);
}

const getSelf = async () => {
  const {data: user} = await axios.get<CurrentUser>(`${API_URL}/reporting/users/me`);

  return user;
}

const getInsuranceProvider = async (insuranceProviderId: number) => {
  const {data: provider} = await axios.get(`${API_URL}/reporting/insurance-providers/${insuranceProviderId}`);

  return provider;
}

const getPatients = async (query: string = '') => {
  let url = `${API_URL}/reporting/data`;
  if (query) {
    url += `?query=${query}`;
  }

  const {data: patients} = await axios.get(url);

  return patients;
}

const createUser = (user: User): Promise<User | undefined> => {
  return axios
    .put(USER_URL, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const updateUser = (user: User): Promise<User | undefined> => {
  return axios
    .post(`${USER_URL}/${user.id}`, user)
    .then((response: AxiosResponse<Response<User>>) => response.data)
    .then((response: Response<User>) => response.data)
}

const deleteUser = (userId: ID): Promise<void> => {
  return axios.delete(`${USER_URL}/${userId}`).then(() => {})
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {getUsers, deleteUser, deleteSelectedUsers, getUserById, createUser, updateUser, getSelf, getInsuranceProvider, getPatients, getRawData, getWidgets, getWidgetConfiguration};

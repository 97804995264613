import React from 'react'
import {
  Widget as WidgetType,
  WidgetData,
} from '../../modules/apps/user-management/users-list/core/_models'
import {getWidgetConfiguration} from '../../modules/apps/user-management/users-list/core/_requests'
import {
  HorizontalBarChart,
  Metric,
  PieChart,
  VerticalBarChart,
} from '../../../_theme/partials/widgets'
import {DateFiltersContext} from '../../../contexts/DateFilters'

interface Props {
  widget: WidgetType
}

const Widget = ({widget}: Props): React.ReactElement | null => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [widgetConfiguration, setWidgetConfiguration] = React.useState<WidgetData | null>(null)
  const {dateFilters} = React.useContext(DateFiltersContext)

  React.useEffect(() => {
    setLoading(true)
    getWidgetConfiguration(widget.id, dateFilters)
      .then(setWidgetConfiguration)
      .then(() => setLoading(false))
  }, [dateFilters, widget.id])

  if (widget.type === 'spacer') {
    return <div className={`hidden-sm col-xl-${widget.width || 3} mb-xl-10`} />
  } else if (widget.type === 'metric') {
    return (
      <div className={`col-sm-6 col-xl-${widget.width || 3} mb-xl-10 mt-4`}>
        <Metric
          text={widget.label}
          filterId={widget.filterable ? widget.id : undefined}
          className='h-lg-100'
          cardNumber={
            widgetConfiguration?.type === 'metric'
              ? widgetConfiguration?.data?.toString() || '0'
              : ''
          }
          color=''
          statistics={''}
          loading={loading}
        />
      </div>
    )
  } else if (widget.type === 'breakdown' || widget.type === 'inputBreakdown') {
    let ChartComponent = VerticalBarChart
    if (widget.chartType === 'pie') {
      ChartComponent = PieChart
    } else if (widget.chartType === 'horizontalBar') {
      ChartComponent = HorizontalBarChart
    }

    return (
      <div className={`col-xl-${widget.width} mb-5 mb-xxl-10 mt-4`}>
        <ChartComponent
          showLegend={widget.showLegend}
          className='h-lg-100'
          chartHeight={widget.height}
          stats={
            widgetConfiguration &&
            (widgetConfiguration.type === 'breakdown' ||
              widgetConfiguration.type === 'inputBreakdown')
              ? widgetConfiguration.data
              : []
          }
          title={widget.label}
          loading={loading}
          useCustomColors={
            widgetConfiguration &&
            (widgetConfiguration.type === 'breakdown' ||
              widgetConfiguration.type === 'inputBreakdown')
              ? widgetConfiguration.useCustomColors
              : false
          }
        />
      </div>
    )
  }

  return null
}

export default Widget
